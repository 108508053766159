/* eslint-disable max-lines */
/* eslint-disable max-len */
import { React } from '@/app'
import { Page, View, Text, Link } from '@/components'
import { Compare } from '@/layout'
import { useApiURL } from '@/utils'

const termsAndConditionsHtml = `
	<div class="scoped-content"><!DOCTYPE html>
<html>
<head>
	<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
	<title></title>
	<meta name="generator" content="LibreOffice 7.4.2.3 (Linux)"/>
	<meta name="author" content="Jo Mason"/>
	<meta name="created" content="2023-12-04T20:27:00"/>
	<meta name="changedby" content="Johanna Mason"/>
	<meta name="changed" content="2023-12-04T20:27:00"/>
	<meta name="AppVersion" content="16.0000"/>
	<style type="text/css"> .scoped-content 
		@page { size: 8.27in 11.69in; margin-left: 1in; margin-right: 1in; margin-top: 0.5in; margin-bottom: 0.5in } .scoped-content 
		p { line-height: 115%; text-align: justify; orphans: 2; widows: 2; margin-bottom: 0.17in; direction: ltr; background: transparent } .scoped-content 
		p.title-western { font-size: 16pt; font-weight: bold; text-align: center; margin-top: 0.17in; margin-bottom: 0.04in } .scoped-content 
		p.title-cjk { font-size: 16pt; font-weight: bold; text-align: center; margin-top: 0.17in; margin-bottom: 0.04in } .scoped-content 
		p.title-ctl { font-size: 16pt; text-align: center; margin-top: 0.17in; margin-bottom: 0.04in } .scoped-content 
		p.body-text-1 { margin-left: 0.5in } .scoped-content 
		p.body-text-3 { margin-left: 1in } .scoped-content 
		p.level-1-bullet { margin-left: 0.5in } .scoped-content 
		p.level-2-bullet { margin-left: 1in } .scoped-content 
		a:link { color: #0000ff; text-decoration: underline } .scoped-content 
		a.western:link { font-family: "Arial", serif } .scoped-content 
	</style>
</head>
<body lang="en-GB" link="#0000ff" vlink="#800000" dir="ltr">
<div title="header"><p align="justify" style="line-height: 115%; margin-bottom: 0.46in; border: none; padding: 0in">
	<br/>
<br/>

	</p>
</div>
<p style="font-size: 16pt; font-family: 'Arial'; font-weight: bold; text-align: center; margin-top: 0.17in; margin-bottom: 0.04in">Cherry Dating Ltd (‘Cherry Dating’) Mobile App Terms and Conditions applicable from July 2023</p>

<p align="left" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid">
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>About
	us</b></font></font></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We   are   Cherry   Dating   Ltd,   a   company   registered   in   the   United   Kingdom   with   company
		number 13734947   and   whose   registered   office   is   71-75   Shelton   Street,   Covent   Garden,
		London, WC2H 9JQ
		(‘</font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Cherry
		Dating’</b></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">)</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Cherry
		Dating is a free online dating application (App). Using an
		algorithm, the app displays potential matches, allowing the user to
		click a heart button to ‘like’ and attempt to match, or a cross
		button to ‘dislike/reject’. [left/right]</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">If
		you have any questions about the App, please contact us by sending
		an email to customerservices@cherrydating.co.uk.</font></font></font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid"><a name="_heading=h.gjdgxs"></a>
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>About
	our terms</b></font></font></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.30j0zll"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">These
		Terms and Conditions (</font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Terms</b></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">)
		explain how you may use the app Cherry Dating and any of its
		content (</font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>App</b></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">).
		These Terms apply between Cherry Dating Ltd (</font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>we</b></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">,
		</font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>us</b></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">
		or </font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>our</b></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">)
		and you, the person accessing or using the App (</font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>you</b></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">,
		</font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>your
		or user</b></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">).</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.1fob9te"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
		should read these Terms carefully before signing up to the App. To
		confirm that you have done so please accept the terms when prompted
		to do so during the signing up process. Acceptance of the Terms
		forms a legally binding agreement between you and us. </font></font></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">By
		registering with us and using and accessing the App, you agree to
		be bound by these Terms and Conditions (the Terms) in their
		entirety. If you do not agree to these Terms you may not use the
		App.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">These
		Terms should be read in conjunction with our Privacy Policy. </font></font></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		reserve the right to update or modify these Terms from time to
		time. Notice of any material change will be posted on this page
		with an updated effective date. We will also notify you of a change
		to the Terms via email, however, you are responsible for regularly
		checking this page for any changes.</font></font></font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid"><a name="_heading=h.3znysh7"></a>
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Using
	the App</b></font></font></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.2et92p0"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
		agree that you are solely responsible for all costs and expenses
		you may incur in relation to your use of the App.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.tyjcwt"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		try to make the App as accessible as possible. If you have any
		difficulties using the App, please contact us using the contact
		details at the top of this page.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.3dy6vkm"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">As
		a condition of your use of the App, you agree to comply with these</font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><i><b>
		</b></i></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Terms,</font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><i><b>
		</b></i></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">available
		on our App, and agree not to:</font></font></font></font></p>
		<ol>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.1t3h5sf"></a>
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">misuse
			or attack our App by knowingly introducing viruses, trojans,
			worms, logic bombs or any other material which is malicious or
			technologically harmful (such as by way of a denial-of-service
			attack), or</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.4d34og8"></a>
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">attempt
			to gain unauthorised access to our App, the server on which our
			App is stored or any server, computer or database connected to our
			App.</font></font></font></font></p>
		</ol>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid"><a name="_heading=h.2s8eyo1"></a>
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Eligibility
	to register </b></font></font></font></font>
	</p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
		are not authorised to register with the App or use the App unless
		all of the following are true, and you hereby represent and warrant
		that:</font></font></font></font></p>
		<ol>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
			are at least 18 years of age;</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
			are capable of entering into a legally binding agreement to use
			our App;</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
			are not prohibited from law or otherwise from using the App or our
			services;</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
			will comply with these Terms and all applicable laws and
			regulations;</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
			do not have more than one account on our App (please see our
			Acceptable Use Rules below); </font></font></font></font>
			</p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
			have not previously been removed from our App by us (unless you
			have our written permission to create a new account); </font></font></font></font>
			</p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
			are not required to register on the sex offenders’ register
			pursuant to the Sexual Offences Act 2003.</font></font></font></font></p>
		</ol>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid"><a name="_heading=h.17dp8vu"></a>
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Registration
	and password security</b></font></font></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.3rdcrjn"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">To
		use the App and benefit from its features you must register on the
		App and create a user account. To register a user account, you will
		be asked to provide your name, date of birth, gender, mobile
		number, email address and a password.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
		will use your username and password to make future logins to the
		App.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
		are responsible for making sure that your password and any other
		account details are kept secure and confidential.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.26in1rg"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		are not obliged to permit anyone to register with the App and we
		may refuse, terminate or suspend registration to anyone at any time
		in accordance with clause 10.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.lnxbz9"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">If
		we have reason to believe there is likely to be a breach of
		security or misuse of the App through your account or the use of
		your password, we may notify you and require you to change your
		password, or we may suspend or terminate your account.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.35nkun2"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Any
		personal information you provide to us as part of the registration
		process will be processed in accordance with our </font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Privacy
		Policy</b></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">
		available on our App.</font></font></font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Profile</b></font></font></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Upon
		registration in accordance with clause 5, each user must create a
		personal profile on the App. </font></font></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Your
		profile will include your name, username, age, a picture(s) and may
		include additional information, such as your:</font></font></font></font></p>
		<ol>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font color="#000000"> <font face="Calibri, serif"><font face="Arial, serif"><font size="2" style="font-size: 10pt">sexual
			orientation;</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">ethnicity;</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">religious
			beliefs;</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">political
			opinions; </font></font></font></font>
			</p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">occupation
			and salary; and/or</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">your
			personal or professional interests.</font></font></font></font></p>
		</ol>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; text-indent: -0.5in; border: none; padding: 0in">
<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Such
additional information is categorised as ‘Special Data’ and will
be processed in accordance with our Privacy Policy. </font></font></font></font>
</p>
<ol>
	<ol start="3">
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Users
		do not have to provide any Special Data however this is recommended
		to enhance the user experience.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Following
		creation of your profile, you will be able to view other user
		profiles and </font></font></font><font face="Arial, serif"><font size="2" style="font-size: 10pt">click
		the heart to like a profile</font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">
		to match with a user.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
		can set your parameters as to where other users are located, within
		your profile settings. We will use location data from your device
		in order to do this. </font></font></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Once
		you have a match both users will be able to use the direct
		messaging feature to send messages or engage in audio and/or video
		calls.</font></font></font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid"><a name="_heading=h.1ksv4uv"></a>
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Infringing
	content</b></font></font></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		prohibit any content that is:</font></font></font></font></p>
		<ol>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Abusive,
			inappropriate, threatening or encourages discriminatory behaviour;</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Defamatory
			or untrue;</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Pornographic,
			violent or contains nudity; </font></font></font></font>
			</p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Infringes
			upon any third party’s rights (including, without limitation,
			intellectual property rights and privacy rights); or</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Contains
			viruses or spyware or other malware designed to interrupt or
			damage any software.</font></font></font></font></p>
		</ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.44sinio"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		will use reasonable efforts to:</font></font></font></font></p>
		<ol>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.2jxsxqh"></a>
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">delete
			accounts which are being used in an inappropriate manner or in
			breach of these Terms; and</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.z337ya"></a>
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">identify
			and remove any content that is inappropriate, defamatory,
			infringes intellectual property rights.</font></font></font></font></p>
		</ol>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; border: none; padding: 0in">
<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">when
we are notified, but we cannot be responsible if you have failed to
provide us with the relevant information.</font></font></font></font></p>
<ol>
	<ol start="3">
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.3j2qqm3"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">If
		you believe that any content which is distributed or published by
		the App is inappropriate, defamatory or infringing on intellectual
		property rights, you should contact us immediately using the
		contact details at the top of this page.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">The
		uploading or sharing of content that violates any of the above
		terms (</font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Infringing
		Content</b></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">)
		may result in the immediate suspension or termination of your
		account.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
		understand and accept that we do not conduct criminal background
		checks on users of the App or otherwise inquire into the background
		of our members and we make no representations or warranties as to
		the conduct or compatibility of our users.</font></font></font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid">
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Your
	Content</b></font></font></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
		confirm that all images, video, sound files, text or information
		that you upload, submit or create whilst using our App (</font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Content</b></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">)
		will meet our Rules Acceptable Use referred to below at clause 9
		and we reserve the right to reject, refuse or delete any Content
		from the App if it breaks our Rules of Acceptable Use.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		may continue to store any Content removed under clause 8.1 to
		comply with certain legal obligations. To the extent permitted by
		applicable law, we shall have no liability for any loss or
		amendment of user Content.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Our
		right to use your Content does not in any way affect your privacy
		rights under our Privacy Policy.</font></font></font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid">
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Rules
	of Acceptable Use </b></font></font></font></font>
	</p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">The
		following rules apply to your use of the App: </font></font></font></font>
		</p>
		<ol>
			<ol type="a">
				<li><p align="justify" style="line-height: 115%; margin-bottom: 0.04in; border: none; padding: 0in">
				<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
				must not use any other person’s personal information when
				registering with the App or purport to be any other person;</font></font></font></font></p>
				<li><p align="justify" style="line-height: 115%; margin-bottom: 0.04in; border: none; padding: 0in">
				<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
				must not allow any other person to use the App under your name;</font></font></font></font></p>
				<li><p align="justify" style="line-height: 115%; margin-bottom: 0.04in; border: none; padding: 0in">
				<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
				must not create more than one profile on the App;</font></font></font></font></p>
				<li><p align="justify" style="line-height: 115%; margin-bottom: 0.04in; border: none; padding: 0in">
				<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
				must not give any false or misleading information either upon
				registration, uploading Content to your profile, engaging with
				other users via direct messaging or otherwise;</font></font></font></font></p>
				<li><p align="justify" style="line-height: 115%; margin-bottom: 0.04in; border: none; padding: 0in">
				<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
				must not upload or share any Infringing Content; </font></font></font></font>
				</p>
				<li><p align="justify" style="line-height: 115%; margin-bottom: 0.04in; border: none; padding: 0in">
				<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
				must not use the App to send hate, junk or spam messages to us or
				other users;</font></font></font></font></p>
				<li><p align="justify" style="line-height: 115%; margin-bottom: 0.04in; border: none; padding: 0in">
				<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
				must not engage in any illegal or unlawful activity;</font></font></font></font></p>
				<li><p align="justify" style="line-height: 115%; margin-bottom: 0.04in; border: none; padding: 0in">
				<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
				must not solicit money from another user, whether as a gift,
				loan, or form of compensation;</font></font></font></font></p>
				<li><p align="justify" style="line-height: 115%; margin-bottom: 0.04in; border: none; padding: 0in">
				<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
				must not encourage, promote, or agree to engage in any activity
				that violates these Terms; </font></font></font></font>
				</p>
				<li><p align="justify" style="line-height: 115%; margin-bottom: 0.04in; border: none; padding: 0in">
				<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
				must not create a new account after we suspend or terminate your
				account, unless you receive our express permission;</font></font></font></font></p>
				<li><p align="justify" style="line-height: 115%; margin-bottom: 0.04in; border: none; padding: 0in">
				<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
				must not share any other users personal data;</font></font></font></font></p>
				<li><p align="justify" style="line-height: 115%; margin-bottom: 0.04in; border: none; padding: 0in">
				<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
				must not use the App if we have banned or removed you from it;</font></font></font></font></p>
				<li><p align="justify" style="line-height: 115%; margin-bottom: 0.04in; border: none; padding: 0in">
				<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
				must ensure your contact details are correct and up to date; and</font></font></font></font></p>
				<li><p align="justify" style="line-height: 115%; margin-bottom: 0.04in; border: none; padding: 0in">
				<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
				must not do or cause to be done anything that will modify,
				intercept or hack our App.</font></font></font></font></p>
			</ol>
		</ol>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 1.5in; text-indent: -0.5in; margin-bottom: 0.04in; border: none; padding: 0in">
<br/>
<br/>

</p>
<ol>
	<ol start="2">
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Failure
		to abide by any of our Rules of Acceptable Use will result in a
		breach of these Terms and may result in us:</font></font></font></font></p>
		<ol>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Taking
			legal action against you to stop you from committing a repeated
			breach and to reimburse us of all costs (including legal and
			administrative) resulting from your breach;</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Suspending
			your access to the App in accordance with clause 10; </font></font></font></font>
			</p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Sending
			you a warning; or </font></font></font></font>
			</p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Removing
			and banning you from the App.</font></font></font></font></p>
		</ol>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid">
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Account
	Suspension </b></font></font></font></font>
	</p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		may prevent or suspend your access to the App if you do not comply
		with these Terms or any applicable law.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		will notify you by email where we decide to suspend your account.
		Once your account has been suspended you will not be able to log in
		or access the App until we have reactivated your access.</font></font></font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid"><a name="_heading=h.1y810tw"></a>
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Your
	privacy and personal information</b></font></font></font></font></p>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; border: none; padding: 0in">
<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Your
privacy and personal information are important to us. Any personal
information that you provide to us will be dealt with in line with
our Privacy Policy available at on our App, which explains what
personal information we collect from you, how and why we collect,
store, use and share such information, your rights in relation to
your personal information and how to contact us and supervisory
authorities in the event you have a query or complaint about the use
of your personal information.</font></font></font></font></p>
<ol start="12">
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid"><a name="_heading=h.4i7ojhp"></a>
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Ownership,
	use and intellectual property rights</b></font></font></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.2xcytpi"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">The
		intellectual property rights in the App and in any text, images,
		video, audio or other multimedia content, software or other
		information or material submitted to or accessible from the App
		(</font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Content</b></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">)
		are owned by us and our licensors.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.1ci93xb"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		and our licensors reserve all our intellectual property rights
		(including, but not limited to, all copyright, trademarks, domain
		names, design rights, database rights, patents and all other
		intellectual property rights of any kind) whether registered or
		unregistered anywhere in the world. This means, for example, that
		we remain owners of them and are free to use them as we see fit.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.3whwml4"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Nothing
		in these Terms grants you any legal rights in the App or the
		Content other than as necessary for you to access it. You agree not
		to adjust, try to circumvent or delete any notices contained on the
		App or the Content (including any intellectual property notices)
		and in particular, in any digital rights or other security
		technology embedded or contained within the App or the Content.</font></font></font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid"><a name="_heading=h.2bn6wsx"></a>
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Submitting
	information to the App</b></font></font></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.qsh70q"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">While
		we try to make sure that the App is secure, we do not actively
		monitor or check whether information supplied to us through the App
		is confidential, commercially sensitive or valuable.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.3as4poj"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Other
		than any personal information which will be dealt with in
		accordance with our Privacy Policy, we do not guarantee that
		information supplied to us through the App will be kept
		confidential and we may use it on an unrestricted and
		free-of-charge basis as we reasonably see fit.</font></font></font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid"><a name="_heading=h.1pxezwc"></a>
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Accuracy
	of information and availability of the app</b></font></font></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.49x2ik5"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		try to make sure that the App is accurate, up-to-date and free from
		bugs, but we cannot promise that it will be. Furthermore, we cannot
		promise that the App will be fit or suitable for any purpose. Any
		reliance that you may place on the information on the App is at
		your own risk.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.147n2zr"></a><a name="_heading=h.2p2csry"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Any
		Content is provided for your general information purposes only and
		to inform you about us and our products and news, features,
		services and other webapps that may be of interest, but has not
		been tailored to your specific requirements or circumstances. It
		does not constitute technical, financial or legal advice or any
		other type of advice and should not be relied on for any purposes.
		You should always use your own independent judgement when using our
		App and its Content.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.3o7alnk"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">While
		we try to make sure that the App is available for your use, we do
		not promise that the App will be available at all times or that
		your use of the App will be uninterrupted.</font></font></font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid">
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Hyperlinks
	and third party apps</b></font></font></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">The
		App may contain hyperlinks or references to third party advertising
		and webapps other than the App. Any such hyperlinks or references
		are provided for your convenience only. We have no control over
		third party advertising or webapps and accept no legal
		responsibility for any content, material or information contained
		in them. The display of any hyperlink and reference to any third
		party advertising or webapp does not mean that we endorse that
		third party’s webapp, products or services. Your use of a third
		party app may be governed by the terms and conditions of that
		third-party app and is at your own risk.</font></font></font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid"><a name="_heading=h.23ckvvd"></a>
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Our
	responsibility to you</b></font></font></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">If
		we breach these Terms or are negligent, we are liable to you for
		foreseeable loss or damage that you suffer as a result. By
		‘foreseeable’ we mean that, at the time these Terms were
		formed, it was either clear that such loss or damage would occur or
		you and we both knew that it might reasonably occur, as a result of
		something we did.</font></font></font></font></p>
	</ol>
	<li><p class="level-1-bullet"><b>We may transfer this Agreement to
	someone else</b></p>
	<ol>
		<li><p class="level-2-bullet"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		may transfer our rights and obligations under these Terms to
		another organisation. We will always tell you in writing if this
		happens and we will ensure that the transfer will not affect your
		rights under the contract. </font></font></font>
		</p>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Limitation
	of liability</b></font></font></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.ihv636"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		are not liable to you for any loss or damage that was not
		foreseeable, any loss or damage </font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>not</b></font></font></font><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">
		caused by our breach or negligence, or any business loss or damage.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		are not liable to you if we fail to comply with these Terms because
		of circumstances beyond our reasonable control.</font></font></font>
		<font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">If
		you report misconduct of another user or another user does not
		comply with section 9, we may provide assistance to you however you
		accept that you are solely responsible for any and all resulting
		direct and indirect losses of such breach and we will under no
		circumstances be held responsible for any damages or losses
		suffered by you. </font></font></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		will not be held responsible for: </font></font></font></font>
		</p>
		<ol>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">actions
			or omissions of users;</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">loss
			or harm caused by viruses, worms or other programmes designed to
			impair the app; any content uploaded by you or other users; or</font></font></font></font></p>
			<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
			<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">any
			amendment to content uploaded by you or other users.</font></font></font></font></p>
		</ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.32hioqz"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Nothing
		in these terms excludes or limits our liability for any death or
		personal injury caused by our negligence, liability for fraud or
		fraudulent misrepresentation, or any other liability that the law
		does not allow us to exclude or limit.</font></font></font></font></p>
	</ol>
	<li><p class="level-1-bullet"><b>No text or data mining, or web
	scraping</b></p>
	<ol>
		<li><p class="level-2-bullet"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
		shall not conduct, facilitate, authorise or permit any text or data
		mining or web scraping in relation to our App or any services
		provided via, or in relation to, our App. This includes using (or
		permitting, authorising or attempting the use of):</font></font></font></p>
		<ol>
			<li><p class="level-2-bullet"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Any
			“robot”, “bot”, “spider”, “scraper” or other
			automated device, program, tool, algorithm, code, process or
			methodology to access, obtain, copy, monitor or republish any
			portion of the App or any data, content, information or services
			accessed via the same.</font></font></font></p>
			<li><p class="level-2-bullet"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Any
			automated analytical technique aimed at analysing text and data in
			digital form to generate information which includes but is not
			limited to patterns, trends and correlations. </font></font></font>
			</p>
		</ol>
		<li><p class="level-2-bullet"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">The
		provisions in this clause should be treated as an express
		reservation of our rights in this regard.</font></font></font></p>
		<li><p class="level-2-bullet"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">This
		clause shall not apply insofar as (but only to the extent that) we
		are unable to exclude or limit text or data mining or web scraping
		activity by contract under the laws which are applicable to us. </font></font></font>
		</p>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid"><a name="_heading=h.1hmsyys"></a>
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Events
	beyond our control</b></font></font></font></font></p>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; border: none; padding: 0in">
<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
are not liable to you if we fail to comply with these Terms because
of circumstances beyond our reasonable control.</font></font></font></font></p>
<ol start="21">
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid"><a name="_heading=h.41mghml"></a>
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>No
	third-party rights</b></font></font></font></font></p>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; border: none; padding: 0in">
<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">No
one other than us or you has any right to enforce any of these Terms.</font></font></font></font></p>
<ol start="22">
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid"><a name="_heading=h.2grqrue"></a>
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Variation</b></font></font></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.vx1227"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">No
		changes to these Terms are valid or have any effect unless agreed
		by us in writing or made in accordance with this clause 20.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.3fwokq0"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		reserve the right to vary these Terms from time to time. Our
		updated Terms will be displayed on the App and by continuing to use
		and access the App following such changes, you agree to be bound by
		any variation made by us. It is your responsibility to check these
		Terms from time to time to verify such variations.</font></font></font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in; page-break-after: avoid"><a name="_heading=h.1v1yuxt"></a>
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Complaints</b></font></font></font></font></p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.4f1mdlm"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">We
		will try to resolve any disputes with you quickly and efficiently.
		If you are unhappy with us, please contact us as soon as possible
		using the contact details set out at the top of this page.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.2u6wntf"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">The
		laws of England and Wales apply to these Terms, although if you are
		resident elsewhere you will retain the benefit of any mandatory
		protections given to you by the laws of that country.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in"><a name="_heading=h.19c6y18"></a>
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Any
		disputes will be subject to the non-exclusive jurisdiction of the
		courts of England and Wales. This means that you can choose whether
		to bring a claim in the courts of England and Wales or in the
		courts of another part of the UK in which you live.</font></font></font></font></p>
	</ol>
	<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
	<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Ending
	use of the App </b></font></font></font></font>
	</p>
	<ol>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">You
		may at any time end your use of the App. If you wish for your
		account to be deactivated or deleted, please contact us from the
		email address registered with us at
		</font></font></font><a class="western" href="mailto:customerservices@cherrydating.co.uk"><font color="#0000ff"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><u>customerservices@cherrydating.co.uk</u></font></font></font></a><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">
		as ask us to delete or deactivate your account. </font></font></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Any
		request made under 23.1 must come from the account holder. </font></font></font></font>
		</p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">If
		at any time after you have downloaded and registered with the App
		you decide you no longer agree to these Terms, you must immediately
		stop using the App.</font></font></font></font></p>
		<li><p align="justify" style="line-height: 115%; border: none; padding: 0in">
		<font face="Calibri, serif"><font color="#000000"><font face="Arial, serif"><font size="2" style="font-size: 10pt">If
		your account is terminated for any reason, these Terms continue and
		remain enforceable between you and us. Your information will be
		maintained and deleted in accordance with our Privacy Policy.</font></font></font></font></p>
	</ol>
	<li><p class="level-1-bullet"><font face="Arial, serif"><font size="2" style="font-size: 10pt"><b>Deletion
	of Data</b></font></font></p>
	<ol>
		<li><p class="level-2-bullet"><font face="Arial, serif"><font size="2" style="font-size: 10pt">Upon
		deactivation or deletion your profile will stop being visible to
		other users. After your account is deleted, we will delete your
		personal information.</font></font></p>
	</ol>
</ol>
<p align="justify" style="line-height: 115%; margin-left: 0.5in; border: none; padding: 0in">
<br/>
<br/>

</p>
<div title="footer"><p align="center" style="line-height: 75%; margin-top: 0.46in; margin-bottom: 0in; border: none; padding: 0in">
	<font color="#000000"><span style="background: #c0c0c0"><sdfield type=PAGE subtype=RANDOM format=PAGE>7</sdfield></span></font></p>
</div>
</body>
</html></div>
`

const TermsAndConditions: React.FC = () => {

  return (
    <Page title='Terms and Condtions' center={true} headerProps={{ white: true }}>

      <View variants={['marginTop:12', 'fullWidth', 'column']}>
        <View variants={['row', 'wrap', 'gap:2']}>
          <Text variants={['black']}>This is the Terms & Conditions Page Access the Privacy & Policy in this link: </Text>
          <Link variants={['black', 'underlined']} text={'Privacy Policy'} to={'/privacy-policy'} />
        </View>
        <div dangerouslySetInnerHTML={{ __html: termsAndConditionsHtml }} />
      </View>
    </Page>
  )
}

export default TermsAndConditions
